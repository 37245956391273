import assetService from '@/services/asset.js';

export default {
  namespaced: true,
  state() {
    return {
      assets: []
    };
  },
  mutations: {
    SET_ASSETS(state, data = []) {
      state.assets = data;
    },
    RESET_STATE(state) {
      state.lists = [];
    }
  },
  actions: {
    async fetchAssetAction({ commit, rootState }) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await assetService.list(token);

        const data = response.data;

        commit('SET_ASSETS', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async addAssetAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await assetService.add(token, payload);
        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async updateAssetAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await assetService.patch(token, payload);

        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    resetStateAction({ commit }) {
      commit('RESET_STATE');
    }
  },
  getters: {
    getAssets(state) {
      return state.assets;
    }
  }
};
