import { createStore } from 'vuex';

import rootMutationsModule from './mutations.js';
import rootGettersModule from './getters.js';
import rootActionsModule from './actions.js';

// modules
import authModule from './modules/auth.js';
import dashboardModule from './modules/dashboard.js';
import roleModule from './modules/role.js';
import adminModule from './modules/admin.js';
import userModule from './modules/user.js';
import transactionModule from './modules/transaction.js';
import webhookModule from './modules/webhook.js';
import assetModule from './modules/asset.js';
import currencyModule from './modules/currency.js';
import countryModule from './modules/country.js';
import walletModule from './modules/wallet.js';
import countryCurrencyModule from './modules/countryCurrency.js';
import reportModule from './modules/report.js';

// initial state
const initialState = {
  balances: [],
  fiatBalances: [],
  sidebarOpen: false,
  error: {
    isError: false,
    statusCode: null,
    message: ''
  }
};

const store = createStore({
  modules: {
    auth: authModule,
    dashboard: dashboardModule,
    role: roleModule,
    admin: adminModule,
    user: userModule,
    transaction: transactionModule,
    webhook: webhookModule,
    asset: assetModule,
    currency: currencyModule,
    country: countryModule,
    wallet: walletModule,
    countryCcy: countryCurrencyModule,
    report: reportModule,
  },
  state() {
    return initialState;
  },
  mutations: rootMutationsModule,
  actions: rootActionsModule,
  getters: rootGettersModule
});

export default store;
