import axiosInstance from '@/config/axios';

async function list(token) {
  const url = '/wallets';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function add(token, payload) {
  const url = '/wallets';

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function patch(token, payload) {
  const url = `/wallets/${payload.wlt_key}`;

  delete payload.wlt_key;

  const response = await axiosInstance.patch(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function remove(token, payload) {
  const url = `/wallets/${payload.wlt_key}`;

  delete payload.wlt_key;

  const response = await axiosInstance.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function walletAssets(token) {
  const url = '/wallet-assets';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function walletCountry(token) {
  const url = '/wallet-countries';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function walletCurrency(token) {
  const url = '/wallet-currencies';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function addWalletAsset(token, payload) {
  const url = '/wallet-assets/add';

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function updateWalletAsset(token, payload) {
  const url = '/wallet-assets/update/' + payload.key;

  delete payload.key;

  const response = await axiosInstance.patch(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function addWalletCountry(token, payload) {
  const url = '/wallet-countries/add';

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function updateWalletCountry(token, payload) {
  const url = '/wallet-countries/update/' + payload.key;

  delete payload.key;

  const response = await axiosInstance.patch(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function filterWalletCountry(token, query) {
  const url = '/wallet-countries/filter?query=' + query;

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function addWalletCurrency(token, payload) {
  const url = '/wallet-currencies/add';

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function updateWalletCurrency(token, payload) {
  const url = '/wallet-currencies/update/' + payload.key;

  delete payload.key;

  const response = await axiosInstance.patch(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

export default {
  list,
  add,
  patch,
  remove,
  walletAssets,
  addWalletAsset,
  walletCountry,
  walletCurrency,
  updateWalletAsset,
  addWalletCountry,
  updateWalletCountry,
  filterWalletCountry,
  addWalletCurrency,
  updateWalletCurrency
};
