import axios from 'axios';

const ENV = process.env.VUE_APP_STAGING_ENV;
const BASE_URL = ENV
  ? process.env.VUE_APP_API_URL_DEV
  : process.env.VUE_APP_API_URL_PROD;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Set default header
    config.headers['Content-Type'] = 'application/json';

    console.log('Making request:', config);
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Handle specific response
    // console.log('Received response:', response);
    return response;
  },
  (error) => {
    // Handle specific error
    console.error('Response error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
