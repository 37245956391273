import axiosInstance from '@/config/axios';

async function list(token) {
  const url = '/countries';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function add(token, payload) {
  const url = '/countries';

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function patch(token, payload) {
  const url = `/countries/${payload.ctry_key}`;

  delete payload.ctry_key;

  const response = await axiosInstance.patch(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function remove(token, payload) {
  const url = `/countries/${payload.ctry_key}`;

  delete payload.ctry_key;

  const response = await axiosInstance.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function filter(token, query) {
  const url = '/countries/filter?query=' + query;

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

export default {
  list,
  add,
  patch,
  remove,
  filter
};
