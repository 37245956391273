export default {
  SET_ERROR(state, { statusCode, message }) {
    state.error.isError = true;
    state.error.message = message;
    state.error.statusCode = statusCode;
  },

  RESET_ERROR(state) {
    state.error.isError = false;
    state.error.message = null;
    state.error.statusCode = null;
  },

  SET_BALANCES(state, data = {}) {
    state.balances = data.balances || [];
  },

  SET_FIAT_BALANCES(state, data = {}) {
    state.fiatBalances = data.balances || [];
  },

  SET_SIDEBARSTATUS(state, data = false) {
    state.sidebarOpen = data;
  }
};
