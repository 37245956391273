import axiosInstance from '@/config/axios';

async function list(token) {
  const url = '/roles';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function add(token, payload) {
  const url = '/roles';

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}
async function patch(token, payload) {
  const url = `/roles/${payload.role_key}`;

  delete payload.role_key;

  const response = await axiosInstance.patch(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function remove(token, payload) {
  const url = `/roles/${payload.role_key}`;

  delete payload.role_key;

  const response = await axiosInstance.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

export default {
  list,
  add,
  patch,
  remove
};
