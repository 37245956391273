import { createApp } from 'vue';
import LoadScript from 'vue-plugin-load-script';
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { VCodeBlock } from '@wdns/vue-code-block';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import App from './App.vue';

import router from './router/index.js';
import store from './store/index.js';

const app = createApp(App);

app.provide('window', window);

// true - stage/dev
// false - prod
const ENV = process.env.VUE_APP_STAGING_ENV;

const siteKey = ENV
  ? process.env.VUE_APP_RECAPTCHA_SITE_KEY_DEV
  : process.env.VUE_APP_RECAPTCHA_SITE_KEY_PROD;

app.component('DataTable', Vue3EasyDataTable);
app.component('VCodeBlock', VCodeBlock);
app.component('VueDatePicker', VueDatePicker);

app.use(VueReCaptcha, {
  siteKey: siteKey,
  loaderOptions: {
    useRecaptchaNet: true
  }
});

app.use(LoadScript);
app.use(Vue3Toasity);

app.use(router);
app.use(store);

app.mount('#app');
