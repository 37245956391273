import authService from '@/services/auth';

const localToken = localStorage.getItem('token') || null;

export default {
  namespaced: true,
  state() {
    return {
      auth: {
        isAuthenticated: localToken ? true : false,
        token: localToken ? JSON.parse(localToken) : null,
        info: {}
      }
    };
  },
  mutations: {
    SET_AUTH(state, payload = {}) {
      state.auth.isAuthenticated = payload.token ? true : false;
      state.auth.token = payload.token || '';
    },
    SET_ADMIN_INFO(state, data = {}) {
      state.auth.info = data;
    },
    RESET_STATE(state) {
      state.auth = {
        isAuthenticated: false,
        token: null,
        info: {}
      };
    }
  },
  actions: {
    async loginAction({ commit }, payload) {
      try {
        const response = await authService.login(payload);

        const data = response.data;
        localStorage.setItem('token', JSON.stringify(data.token));
        commit('SET_AUTH', response.data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },

    async siteVerifyAction({ commit }, payload) {
      try {
        const response = await authService.siteVerify(payload);

        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async adminInfoAction({ state, commit }) {
      const token = state.auth.token || '';

      if (!token) {
        throw new Error('missing token');
      }

      try {
        const response = await authService.details(token);

        const data = response.data;

        commit('SET_ADMIN_INFO', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    logoutAction({ commit }) {
      localStorage.removeItem('token');
      commit('SET_AUTH', {});
    },
    resetStateAction({ commit }) {
      commit('RESET_STATE');
    }
  },
  getters: {
    getAuth(state) {
      return state.auth;
    },
    getAdminInfo(state) {
      return state.auth.info;
    }
  }
};
