import axiosInstance from '@/config/axios';

async function list(token) {
  const url = '/users';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function filter(token, query) {
  const url = `/users/filter?query=${query}`;

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function details(token, userKey) {
  const url = `/users/details/${userKey}`;

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

export default {
  list,
  filter,
  details
};
