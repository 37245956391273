import reportService from '@/services/report.js';

export default {
  namespaced: true,
  state() {
    return {
      report: {}
    };
  },
  mutations: {
    SET_REPORT_INFO(state, data = {}) {
      state.report = data;
    },
    RESET_STATE(state) {
      state.report = {};
    }
  },
  actions: {
    async fetchReportAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await reportService.report(token, payload);

        const data = response.data;

        commit('SET_REPORT_INFO', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    resetStateAction({ commit }) {
      commit('RESET_STATE');
    }
  },
  getters: {
    getReportInfo(state) {
      return state.report;
    }
  }
};
