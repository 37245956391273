import adminService from '@/services/admin.js';

export default {
  namespaced: true,
  state() {
    return {
      lists: [],
      details: null
    };
  },
  mutations: {
    SET_ADMINS(state, data = []) {
      // const list = data.filter((ad) => {
      //   return state.auth.info.admin_key !== ad.admin_key;
      // });
      state.lists = data;
    },
    RESET_STATE(state) {
      state.lists = [];
    }
  },
  actions: {
    async fetchAdminAction({ commit, rootState }) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await adminService.list(token);

        const data = response.data;

        commit('SET_ADMINS', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async addAdminAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing token');
      }

      try {
        const response = await adminService.add(token, payload);
        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async updateAdminAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing token');
      }

      try {
        const response = await adminService.patch(token, payload);

        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async removeAdminAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing token');
      }

      try {
        const response = await adminService.remove(token, payload);

        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async updateAdminProfileAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing token');
      }

      try {
        const response = await adminService.adminProfilePatch(token, payload);

        return response;
      } catch (error) {
        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        commit('SET_ERROR', {
          statusCode,
          message
        });
        throw new Error(message);
      }
    },
    resetStateAction({ commit }) {
      commit('RESET_STATE');
    }
  },
  getters: {
    getAdmins(state) {
      return state.lists;
    }
  }
};
