import axiosInstance from '@/config/axios';

async function login(payload) {
  const url = '/auth/login';

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function siteVerify(payload) {
  const url = '/auth/site-verify';

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function details(token) {
  const url = '/admins/info';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

export default {
  login,
  siteVerify,
  details
};
