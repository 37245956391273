// import { h, resolveComponent } from 'vue';

const MainRoutes = {
  path: '/main',
  meta: {
    requiresAuth: true
  },
  redirect: '/main',
  component: () => import('@/layouts/full/FullLayout.vue'),
  children: [
    {
      name: 'Dashboard',
      path: '/',
      component: () => import('@/pages/dashboard/index.vue')
    },
    {
      name: 'Transaction',
      path: '/transactions',
      component: () => import('@/pages/transaction/index.vue')
    },
    {
      name: 'TransactionDetails',
      path: '/transaction/details/:txKey',
      props: true,
      component: () => import('@/pages/transaction/Details.vue')
    },
    {
      name: 'TransactionStatus',
      path: '/transaction/status',
      props: true,
      component: () => import('@/pages/transaction/Status.vue')
    },
    {
      name: 'TransactionStellar',
      path: '/transaction/stellar',
      props: true,
      component: () => import('@/pages/transaction/Stellar.vue')
    },
    {
      name: 'User',
      path: '/users',
      component: () => import('@/pages/user/index.vue')
    },
    {
      name: 'UserDetails',
      path: '/user/details/:userKey',
      props: true,
      component: () => import('@/pages/user/Details.vue')
    },
    {
      name: 'Profile',
      path: '/auth/profile',
      component: () => import('@/pages/profile/index.vue')
    },
    {
      name: 'Admin',
      path: '/admins',
      component: () => import('@/pages/admin/index.vue')
    },
    {
      name: 'Role',
      path: '/roles',
      component: () => import('@/pages/role/index.vue')
    },
    {
      name: 'Webhook',
      path: '/webhooks',
      component: () => import('@/pages/webhooks/index.vue')
    },
    {
      name: 'Wallet',
      path: '/wallets/list',
      component: () => import('@/pages/wallet/index.vue')
    },
    {
      name: 'WalletAsset',
      path: '/wallets/asset',
      component: () => import('@/pages/walletAsset/index.vue')
    },
    {
      name: 'WalletCountry',
      path: '/wallets/country',
      component: () => import('@/pages/walletCountry/index.vue')
    },
    {
      name: 'WalletCurrency',
      path: '/wallets/currency',
      component: () => import('@/pages/walletCurrency/index.vue')
    },
    {
      name: 'Asset',
      path: '/assets',
      component: () => import('@/pages/assets/index.vue')
    },
    {
      name: 'Country',
      path: '/countries',
      component: () => import('@/pages/country/index.vue')
    },
    {
      name: 'Currency',
      path: '/currencies',
      component: () => import('@/pages/currency/index.vue')
    },
    {
      name: 'CountryCurrency',
      path: '/country-currencies',
      component: () => import('@/pages/countryCurrency/index.vue')
    },
    {
      name: 'Report',
      path: '/report',
      component: () => import('@/pages/report/index.vue')
    }
  ]
};

export default MainRoutes;
