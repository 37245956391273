import transactionService from '@/services/transaction.js';

export default {
  namespaced: true,
  state() {
    return {
      lists: [],
      details: {},
      fiatTransferDetails: {},
      stellarTxnDetails: {}
    };
  },
  mutations: {
    SET_TXN(state, data = []) {
      state.lists = data;
    },
    SET_TXN_DETAILS(state, data = {}) {
      state.details = data;
    },
    SET_FIAT_DETAILS(state, data = {}) {
      state.fiatTransferDetails = data;
    },
    SET_STELLAR_TXN(state, data = {}) {
      state.stellarTxnDetails = data;
    },
    // reset state
    RESET_STATE(state) {
      state.lists = [];
      state.details = {};
      state.fiatTransferDetails = {};
      state.stellarTxnDetails = {};
    }
  },
  actions: {
    async fetchTxnAction({ commit, rootState }) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await transactionService.list(token);

        const data = response.data;

        commit('SET_TXN', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async fetchTxnDetailsAction({ commit, rootState }, txKey) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await transactionService.details(token, txKey);

        const data = response.data;

        commit('SET_TXN_DETAILS', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async fetchTxnFiatStatusAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await transactionService.fiatStatus(
          token,
          payload.txn_ref
        );

        const data = response.data;

        commit('SET_FIAT_DETAILS', data);
        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async fiatTxnRetryAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await transactionService.fiatRetry(token, payload);

        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async fiatTxnInitiateAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await transactionService.fiatInitiate(token, payload);

        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async refundTxnAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await transactionService.refunded(token, payload);

        return response;
      } catch (error) {
        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async completedTxnAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await transactionService.completed(token, payload);

        return response;
      } catch (error) {
        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async filterTxnsAction({ commit, rootState }, queryObj) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await transactionService.filter(token, queryObj);

        const data = response.data;

        commit('SET_TXN', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async stellarTxnDetailsAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await transactionService.stellarTransaction(
          token,
          payload
        );
        const data = response.data;
        commit('SET_STELLAR_TXN', data);
        return response;
      } catch (error) {
        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    resetStateAction({ commit }) {
      commit('RESET_STATE');
    }
  },
  getters: {
    getTxns(state) {
      return state.lists;
    },

    getTxnDetails(state) {
      return state.details || {};
    },
    getFiatTransferDetails(state) {
      return state.fiatTransferDetails;
    },
    getStellarTxnDetails(state) {
      return state.stellarTxnDetails;
    }
  }
};
