export default {
  getError(state) {
    return state.error;
  },

  getBalances(state) {
    return state.balances;
  },
  getFiatBalances(state) {
    return state.fiatBalances;
  }
};
