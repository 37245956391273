import countryCurrencyService from '@/services/countryCurrency.js';

export default {
  namespaced: true,
  state() {
    return {
      countryCcy: []
    };
  },
  mutations: {
    SET_COUNTRY_CURRENCIES(state, data = []) {
      state.countryCcy = data;
    },
    RESET_STATE(state) {
      state.countryCcy = [];
    }
  },
  actions: {
    async fetchCountryCurrencyAction({ commit, rootState }) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await countryCurrencyService.list(token);

        const data = response.data;

        commit('SET_COUNTRY_CURRENCIES', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async addCountryCurrencyAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await countryCurrencyService.add(token, payload);
        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    resetStateAction({ commit }) {
      commit('RESET_STATE');
    }
  },
  getters: {
    getCountryCurrency(state) {
      return state.countryCcy;
    }
  }
};
