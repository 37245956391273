import { createRouter, createWebHistory } from 'vue-router';
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';

import store from '@/store';

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-item',
  routes: [MainRoutes, AuthRoutes]
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.state['auth']?.auth.isAuthenticated) {
    next({ name: 'Login' });
  } else if (to.name === 'Login' && store.state['auth']?.auth.isAuthenticated) {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
});

export default router;
