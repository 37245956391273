<template>
  <!-- Layout wrapper -->
  <RouterView />
  <!-- / Layout wrapper -->
</template>

<script>
import { toast } from 'vue3-toastify';
import { mapActions } from 'vuex';
import { inject } from 'vue';

export default {
  name: 'App',
  methods: {
    ...mapActions('auth', ['adminInfoAction']),
    ...mapActions(['getError'])
  },
  async mounted() {
    // sidebar open false
    this.$store.commit('SET_SIDEBARSTATUS');

    const windowObject = inject('window');

    windowObject.onload = async () => {
      await this.$loadScript('/assets/vendor/libs/jquery/jquery.js');
      await this.$loadScript('/assets/vendor/libs/popper/popper.js');
      await this.$loadScript('/assets/vendor/js/bootstrap.js');
      await this.$loadScript(
        '/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js'
      );
      await this.$loadScript('/assets/vendor/js/menu.js');
      await this.$loadScript('/assets/vendor/libs/apex-charts/apexcharts.js');
      await this.$loadScript('/assets/js/main.js');
      await this.$loadScript('/assets/js/dashboards-analytics.js');
      await this.$loadScript('https://buttons.github.io/buttons.js');
    };

    try {
      await this.adminInfoAction();
    } catch (err) {
      toast.clearAll();

      this.getError.isError &&
        toast.error(this.getError.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          transition: toast.TRANSITIONS.SLIDE,
          icon: true,
          closeButton: false,
          hideProgressBar: true,
          autoClose: 3000,
          onClose: () => {
            if (this.getError.statusCode === 401) {
              this.$router.push({
                name: 'Login'
              });
            }
          }
        });
    }
  }
};
</script>

<style>
.Toastify__toast-container {
  width: 34.6rem !important;
}

.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #2d3a4f;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-scrollbar-thumb-color: #4c5d7a;
  --easy-table-scrollbar-corner-color: #2d3a4f;
}
</style>
