import axios from 'axios';

// true - stage/dev
// false - prod
const ENV = process.env.VUE_APP_STAGING_ENV;
const BASE_URL = ENV
  ? process.env.VUE_APP_API_URL_DEV
  : process.env.VUE_APP_API_URL_PROD;

export default {
  resetErrorsAction({ commit }, payload = {}) {
    commit('RESET_ERROR', payload);
  },

  async distributionAccount({ state, commit }) {
    const url = BASE_URL + '/anchor/distribution-account';
    const token = state['auth'].auth.token || '';

    if (!token) {
      throw new Error('missing token');
    }

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data.data;

      commit('SET_BALANCES', data);
    } catch (error) {
      const statusCode = error.response?.status || 500;
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      commit('SET_ERROR', {
        statusCode,
        message
      });
      throw new Error(message);
    }
  },

  async fetchFiatBalanceAction({ state, commit }) {
    const url = BASE_URL + '/fiat/balance';
    const token = state['auth'].auth.token || '';

    if (!token) {
      throw new Error('missing token');
    }

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data.data;

      commit('SET_FIAT_BALANCES', data);
    } catch (error) {
      const statusCode = error.response?.status || 500;
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      commit('SET_ERROR', {
        statusCode,
        message
      });
      throw new Error(message);
    }
  }
};
