import axiosInstance from '@/config/axios';

async function list(token) {
  const url = '/transactions';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function details(token, txKey) {
  const url = `/transactions/details/${txKey}`;

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function filter(token, queryObj) {
  const query = new URLSearchParams();

  for (const key in queryObj) {
    if (Object.hasOwnProperty.call(queryObj, key)) {
      query.append(key, queryObj[key]);
    }
  }

  const url = '/transactions/filter?' + query;

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function fiatStatus(token, txn_ref) {
  const url = `/transactions/status/${txn_ref}`;

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function fiatRetry(token, payload) {
  const url = `/transactions/retry`;

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function fiatInitiate(token, payload) {
  const url = `/transactions/fiat-initiate`;

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function completed(token, payload) {
  const url = `/transactions/completed/${payload.tx_key}`;

  const response = await axiosInstance.patch(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function refunded(token, payload) {
  const url = `/transactions/refund/${payload.tx_key}`;

  const response = await axiosInstance.patch(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

async function stellarTransaction(token, payload) {
  const url = '/transactions/stellar/status';

  const response = await axiosInstance.post(url, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

export default {
  list,
  details,
  filter,
  fiatStatus,
  fiatRetry,
  fiatInitiate,
  refunded,
  completed,
  stellarTransaction
};
