import dashboardService from '@/services/dashboard';

export default {
  namespaced: true,
  state() {
    return {
      dashboard: {
        summary: {},
        users: [],
        transactions: []
      }
    };
  },
  mutations: {
    SET_DASHBOARD_INFO(state, data = {}) {
      state.dashboard.summary = data.summary;
      state.dashboard.users = data.users;
      state.dashboard.transactions = data.transactions;
    },
    RESET_STATE(state) {
      state.dashboard.summary = {};
      state.dashboard.users = [];
      state.dashboard.transactions = [];
    }
  },
  actions: {
    async dashboardSummeryAction({ commit, rootState }) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await dashboardService.summary(token);

        const data = response.data;

        commit('SET_DASHBOARD_INFO', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    resetStateAction({ commit }) {
      commit('RESET_STATE');
    }
  },
  getters: {
    getDashboardSummery(state) {
      return state.dashboard.summary || {};
    },
    getDashboardTxn(state) {
      return state.dashboard.transactions || [];
    },
    getDashboardUser(state) {
      return state.dashboard.users || [];
    }
  }
};
