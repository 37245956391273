import webhookService from '@/services/webhook.js';

export default {
  namespaced: true,
  state() {
    return {
      webhooks: []
    };
  },
  mutations: {
    SET_WEBHOOKS(state, data = []) {
      state.webhooks = data;
    },
    RESET_STATE(state) {
      state.webhooks = [];
    }
  },
  actions: {
    async fetchWebhookAction({ commit, rootState }) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await webhookService.list(token);

        const data = response.data;

        commit('SET_WEBHOOKS', data);
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    async addWebhookAction({ commit, rootState }, payload) {
      const token = rootState.auth['auth'].token || '';

      if (!token) {
        throw new Error('missing authorization token');
      }

      try {
        const response = await webhookService.add(token, payload);
        return response;
      } catch (error) {
        console.error(error);

        const statusCode = error.response?.status || 500;
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        commit(
          'SET_ERROR',
          {
            statusCode,
            message
          },
          { root: true }
        );
        throw new Error(message);
      }
    },
    resetStateAction({ commit }) {
      commit('RESET_STATE');
    }
    // async updateWebhookAction({ commit, rootState }, payload) {
    //   const token = rootState.auth['auth'].token || '';

    //   if (!token) {
    //     throw new Error('missing authorization token');
    //   }

    //   try {
    //     const response = await webhookService.patch(token, payload);

    //     return response;
    //   } catch (error) {
    //     console.error(error);

    //     const statusCode = error.response?.status || 500;
    //     const message =
    // (error.response &&
    //   error.response.data &&
    //   error.response.data.message) ||
    // error.message ||
    // error.toString();
    //     commit(
    //       'SET_ERROR',
    //       {
    //         statusCode,
    //         message
    //       },
    //       { root: true }
    //     );
    //     throw new Error(message);
    //   }
    // },
    // async removeWebhookAction({ commit, rootState }, payload) {
    //   const token = rootState.auth['auth'].token || '';

    //   if (!token) {
    //     throw new Error('missing authorization token');
    //   }

    //   try {
    //     const response = await webhookService.remove(token, payload);

    //     return response;
    //   } catch (error) {
    //     console.error(error);

    //     const statusCode = error.response?.status || 500;
    //     const message =
    // (error.response &&
    //   error.response.data &&
    //   error.response.data.message) ||
    // error.message ||
    // error.toString();
    //     commit(
    //       'SET_ERROR',
    //       {
    //         statusCode,
    //         message
    //       },
    //       { root: true }
    //     );
    //     throw new Error(message);
    //   }
    // }
  },
  getters: {
    getWebhooks(state) {
      return state.webhooks;
    }
  }
};
