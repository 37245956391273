import axiosInstance from '@/config/axios';

async function summary(token) {
  const url = '/dashboard';

  const response = await axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  if (!response.data) {
    throw new Error('Unexpected response from server');
  }

  return response.data;
}

export default {
  summary
};
